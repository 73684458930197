/*
	Table of Contents

	     01. Tools ------------------------------------
	I.   Generic --------------------------------------
	     01. Reset ------------------------------------
	     02. Base -------------------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     03. Fonts ------------------------------------
	     04. Helpers ----------------------------------
	         Clear ------------------------------------
	         Notext -----------------------------------
	         Hidden -----------------------------------
	         Alignleft --------------------------------
	         Alignright -------------------------------
	         Disabled ---------------------------------
	         Grid -------------------------------------
	         Old IE fallback --------------------------
	         Blue -------------------------------------
	         Responsive Helpers -----------------------
	         Bulleted lists ---------------------------
	II.  Regions --------------------------------------
	     01. Container --------------------------------
	     02. Content ----------------------------------
	     03. Footer -----------------------------------
	         Tablet Portrait --------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	     04. Main -------------------------------------
	     05. Sidebar ----------------------------------
	     06. Wrapper ----------------------------------
	III. Modules --------------------------------------
	     01. Auth Box ---------------------------------
	         Section Aside ----------------------------
	         Auth Box Content -------------------------
	         Auth Box 3d ------------------------------
	         Auth Box Fullscreen ----------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     02. Avatar -----------------------------------
	         Section Aside ----------------------------
	         Avatar Content ---------------------------
	         Avatar 3d --------------------------------
	         Avatar Fullscreen ------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     03. Blockquote -------------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     04. Box --------------------------------------
	         Box Entry --------------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     05. # Button ---------------------------------
	         Mobile -----------------------------------
	     06. Callout ----------------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     07. Copyrights -------------------------------
	         Mobile -----------------------------------
	     08. Form Elements ----------------------------
	         Select -----------------------------------
	         Radio ------------------------------------
	         Checkbox ---------------------------------
	         Checkbox Alt -----------------------------
	         Mobile -----------------------------------
	     09. Form -------------------------------------
	         Form Label -------------------------------
	         Form Head --------------------------------
	         Form Body --------------------------------
	         Form Row ---------------------------------
	         Form Controls ----------------------------
	         Form Password ----------------------------
	         Form Actions -----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     10. Intro ------------------------------------
	         Intro Image ------------------------------
	         Intro Content ----------------------------
	         Intro Entry ------------------------------
	         Tablet Portrait --------------------------
	     11. # Lists ----------------------------------
	     12. List Links -------------------------------
	     13. List Measurements ------------------------
	         Mobile -----------------------------------
	     14. List Logos -------------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     15. List Tags --------------------------------
	         Mobile -----------------------------------
	     16. List Info --------------------------------
	         Mobile -----------------------------------
	     17. List Radios ------------------------------
	         Tablet Portrait --------------------------
	     18. List Checkboxes --------------------------
	         Mobile -----------------------------------
	     19. List Numbers -----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     20. List Colors ------------------------------
	         Mobile -----------------------------------
	     21. List Brands ------------------------------
	         Mobile -----------------------------------
	     22. List Awards ------------------------------
	         Mobile -----------------------------------
	     23. List Partners ----------------------------
	         Mobile -----------------------------------
	     24. List Partners ----------------------------
	         Mobile -----------------------------------
	     25. # Navigations ----------------------------
	         Navbar Brand -----------------------------
	         Navbar Collapse --------------------------
	         Navbar Nav -------------------------------
	         Toggle -----------------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     26. Profiles ---------------------------------
	         Profile Inner ----------------------------
	         Profile Wrapper --------------------------
	         Profile Image ----------------------------
	         Profile Content --------------------------
	         Profile Overlay --------------------------
	         Profile Overlay Inner --------------------
	         Tablet -----------------------------------
	         Mobile -----------------------------------
	     27. Requests ---------------------------------
	         Requests Head ----------------------------
	         Requests Body ----------------------------
	         Mobile -----------------------------------
	     28. Search -----------------------------------
	         Search Field -----------------------------
	         Search Button ----------------------------
	         Search Dropdown --------------------------
	         Mobile -----------------------------------
	     29. Section ----------------------------------
	         Section Head -----------------------------
	         Section Image ----------------------------
	     30. Section Businesses -----------------------
	         Section Head -----------------------------
	         Section Body -----------------------------
	         Section Foot -----------------------------
	         Tablet -----------------------------------
	         Mobile -----------------------------------
	     31. Section Legal ----------------------------
	         Section Head -----------------------------
	         Section Body -----------------------------
	         Section Foot -----------------------------
	         Tablet -----------------------------------
	         Mobile -----------------------------------
	     32. Section Profiles -------------------------
	     33. Section Split ----------------------------
	         Mobile -----------------------------------
	     34. Section Background -----------------------
	         Section Head -----------------------------
	         Section Body -----------------------------
	         Section Foot -----------------------------
	         Section Aside ----------------------------
	         Section Content --------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     35. Section Demo -----------------------------
	         Section Body -----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     36. Section Intro ----------------------------
	         Section Head -----------------------------
	         Section Body -----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     37. Section Intro panel ----------------------
	         Section Head -----------------------------
	         Section Body -----------------------------
	         Section Foot -----------------------------
	         Tablet -----------------------------------
	         Mobile -----------------------------------
	     38. Section Quote ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     39. Section Video ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     40. Section Brands ---------------------------
	     41. Section Benefits -------------------------
	     42. Signin -----------------------------------
	         Signin Inner -----------------------------
	         Signin Head ------------------------------
	         Signin Body ------------------------------
	         Signin Actions ---------------------------
	         Small Desktop ----------------------------
	         Tablet Portrait --------------------------
	         Mobile -----------------------------------
	     43. Slider -----------------------------------
	     44. Socials  ---------------------------------
	     45. Subscribe --------------------------------
	         Subscribe Field --------------------------
	         Subscribe Button -------------------------
	     46. Table ------------------------------------
	     47. Tiles ------------------------------------
	         Tiles Item Content -----------------------
	         Tiles Item Entry -------------------------
	         Mobile -----------------------------------
	     48. Video ------------------------------------
	         Video Content ----------------------------
	         Video Button -----------------------------
	         Mobile -----------------------------------
	     49. Widget -----------------------------------
 */

/* ------------------------------------------------------------ *\
	Tools
\* ------------------------------------------------------------ */
@define-placeholder clearfix { content: ''; line-height: 0; display: table; clear: both; }
@define-placeholder center { position: absolute; left: 50%; top: 50%; -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%); }
@define-placeholder full { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
@define-placeholder imageCover { background-size: cover; background-position: center center; background-repeat: no-repeat; }
@define-placeholder imageContain { background-size: contain; background-position: center center; background-repeat: no-repeat; }
/* use: @extend clearfix; @extend center; */
/* ------------------------------------------------------------ *\
	I.   Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
*,
*:before,
*:after { padding: 0; margin: 0; outline: 0; box-sizing: border-box; }
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main { display: block; }
html { tap-highlight-color: rgba(0,0,0,0); }
body { -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; text-size-adjust: none; }
img,

video,
audio,
object { max-width: 100%; }
img,
iframe { border: 0 none; }
img { height: auto; display: inline-block; vertical-align: middle; }
b,
strong { font-weight: bold; }
address { font-style: normal; }
svg:not(:root) { overflow: hidden; }
a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] { cursor: pointer; }
a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] { cursor: default; }
button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
textarea { overflow: auto; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; }
button,
input,
optgroup,
select,
textarea { font-family: inherit; font-size: inherit; color: inherit; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }
button,
select { text-transform: none; }
table { width: 100%; border-collapse: collapse; border-spacing: 0; }
nav ul,
nav ol { list-style: none outside none; }
/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
body { min-width: 320px; background: #fff; font-family: 'Futura BT', sans-serif; font-size: 18px; line-height: 1.67; color: #313d47; font-weight: 400; }
p { font-weight: 500; }
p.centered { text-align: center }
a { color: inherit; text-decoration: none; }
a:not(.no-style) { transition: all 0.4s; }
a:hover,
a[href^="tel"] { color: #ff5c36; text-decoration: none; }
a.no-style:hover { color: inherit; }
h1,
h2,
h3,
h4,
h5,
h6 { margin-bottom: 0.835em; font-weight: normal; }
h5,
h6 { text-align: left; }
h1 { font-size: 50px; line-height: 1.2; letter-spacing: 2.1px; }
h2 { font-size: 42px; line-height: 1.1; font-weight: 500; letter-spacing: .6px; }
h3 { font-size: 28px; line-height: 1.79; letter-spacing: 1.1px; }
h4 { font-size: 24px; line-height: 1.25; font-weight: 500; letter-spacing: 1.2px; }
h5 { font-size: 18px; }
h6 { font-size: 12px; line-height: 1.92; letter-spacing: 0.65px; }
p,
ul,
ol,
dl,
hr,
table,
blockquote { margin-bottom: 0.5rem;}
h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }
figure:not(:last-child) { margin-bottom: 45px; }
figcaption { margin-top: 17px; margin-left: 2px; font-size: 16px; line-height: 1.88; font-weight: 300; font-style: italic; letter-spacing: 0.4px; }
body.show-fixed-header .header { padding: 20px 0; background: #fff; }
body.show-fixed-header .navbar-nav a { color: #313d47; }
body.show-fixed-header .navbar-nav .active a,
body.show-fixed-header .navbar-nav a:hover { color: #ff5c36; }
body .navbar-nav a.curr-page { color: #ff5c36; }
body .navbar-nav a.curr-page:hover { color: #313d47; }
body.show-fixed-header .navbar .btn { color: #313d47; }
body.show-fixed-header .navbar .btn:hover { color: #fff; }
body.show-fixed-header .navbar-brand img + img { opacity: 1; visibility: visible; }
body.show-nav-main .btn-menu span { background-color: transparent; }
body.show-nav-main .btn-menu span:before,
body.show-nav-main .btn-menu span:after { margin-top: -1px; }
body.show-nav-main .btn-menu span:before { -ms-transform: rotate(45deg); transform: rotate(45deg); }
body.show-nav-main .btn-menu span:after { -ms-transform: rotate(-45deg); transform: rotate(-45deg); }
body.no-scroll {
	height: 100vh;
	overflow: hidden;

}
/* Tablet Portrait */
@media (max-width: 1024px) {
	body.show-fixed-header .header { padding: 10px 0; background: #fff; }

	body.show-fixed-header .navbar-toggler span { background: #313d47; }
	body.show-fixed-header .navbar-toggler[aria-expanded="true"] span { background: transparent; }

	body.show-fixed-header .navbar-toggler[aria-expanded="true"] span:first-child,
	body.show-fixed-header .navbar-toggler[aria-expanded="true"] span:last-child { background: #313d47; }

	figcaption { margin-top: 10px; margin-left: 0; }
}

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-Light.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-Book.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-Medium.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-Bold.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Futura BT';
    src: url('/assets/fonts/FuturaBT-Heavy.woff2') format('woff2'),
        url('/assets/fonts/FuturaBT-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after { content: ''; line-height: 0; display: table; clear: both; }
/*  Notext  */
.notext { white-space: nowrap; text-indent: 100%; text-shadow: none; overflow: hidden; }
.notext-old { font-size: 0; line-height: 0; text-indent: -4000px; }
/*  Hidden  */
[hidden],
.hidden { display: none; }
/*  Alignleft  */
.alignleft { float: left; }
/*  Alignright  */
.alignright { float: right; }
/*  Disabled  */
[disabled],
.disabled { cursor: default; }
/*  Grid  */
.cols { margin: 0 -15px; }
.cols:after { @extend clearfix; }
.col { float: left; width: 100%; padding: 0 15px; }
.col-1of2 { width: 50%; }
/*  Old IE fallback  */
.browsehappy { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; margin: 0; padding-top: 50px; background: #e0e0e0; text-align: center; }
/*  Blue  */
.color { color: #0f0e9f; }
/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block { display: none !important; }

@media (min-width: 768px) and (max-width: 1024px) {
	.hidden-sm { display: none !important; }
	.visible-sm-block { display: block !important; }
	.visible-sm-inline { display: inline !important; }
	.visible-sm-inline-block { display: inline-block !important; }
}
@media (min-width: 1025px) and (max-width: 1200px) {
	.hidden-md { display: none !important; }
	.visible-md-block { display: block !important; }
	.visible-md-inline { display: inline !important; }
	.visible-md-inline-block { display: inline-block !important; }
}
@media (min-width: 1201px) {
	.hidden-lg { display: none !important; }
	.visible-lg-block { display: block !important; }
	.visible-lg-inline { display: inline !important; }
	.visible-lg-inline-block { display: inline-block !important; }
}
/* Bulleted lists */
.bulleted-list-container {
	margin-left: 30px;
	font-size: 23px;
}
a.signup:hover { color: #0b19a9; text-decoration: none; }
a.forgot-password:hover { color: #212529; text-decoration: none; }
a.forgot-password { color: #fff; }
h3.warning-no-model { text-align: left; text-transform: none; font-size: 24px; color: #ff5c36; }
@media (min-width: 768px) {
	#mc_embed_signup { min-width: 400px; }
}
/* ------------------------------------------------------------ *\
	II.  Regions
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */
.container { max-width: 1360px; padding-left: 15px; padding-right: 15px; margin: auto; }
.container-md { max-width: 1307px; }
.container-sm { max-width: 755px; }
.container-fluid { max-width: none; padding: 0; }
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
.content {}
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer { padding: 53px 0 90px; background: #313d47; font-size: 14px; line-height: 1.67; color: #fff; letter-spacing: 0.4px; }
.footer h6 { margin-bottom: 18px; }
.footer [href^="tel"] { color: #fff; }
.footer [class^="col"]:last-child { text-align: right; }
.footer .socials { -ms-transform: translateY(-6px); transform: translateY(-6px); }
.footer .socials ul { -ms-flex-pack: end; justify-content: flex-end; }
.footer .copyrights { margin-top: 118px; }
@media (min-width: 992px) {
	.footer .col-lg-offset-2 { margin-right: 16.666667%; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.footer { padding: 50px 0; }
}
@media(max-width: 991px) {
	.footer .copyrights { margin-top: 0; }
	.footer .socials { -ms-transform: none; transform: none; margin-bottom: 10px; }
	.footer .socials ul { -ms-flex-pack: center; justify-content: center; }
	.footer [class^="col"]:last-child { margin-top: 30px; text-align: center; }
}

/* ------------------------------------------------------------ *\
	# Header
\* ------------------------------------------------------------ */
.header { position: fixed; top: 0; right: 0; left: 0; z-index: 50; padding: 30px 0; transition: padding 0.4s, background 0.4s; }
.header .container { max-width: 100%; padding: 0 56px; }
.header .navbar-nav:not(:last-child) { padding-right: 30px; }
/*  --secondary  */
.header--secondary .navbar a { color: #313d47; }
.header--secondary .navbar .btn:hover { color: #fff; }
/*.header.header--secondary { padding-bottom: 25px; padding-top: 25px; }*/
/* Small Desktop */
@media (max-width: 1200px) {
	.header .container { padding: 0 15px; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.header { padding: 10px 0; }

	.header .btn { position: static; -ms-transform: none; transform: none; }
	.header .navbar-brand { position: relative; top: 0; left: 0; -ms-transform: none; transform: none; }

	.header .navbar-nav { padding-left: 0; }
	.header .navbar-nav:not(:last-child) { padding-right: 0; }

	.header--secondary .navbar-toggler span,
	.header--secondary .navbar-toggler span:first-child,
	.header--secondary .navbar-toggler span:last-child { background: #313d47; }
}
/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
.main {}
/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
.sidebar {}
/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper { min-height: 100vh; overflow: hidden; }
/* ------------------------------------------------------------ *\
	III. Modules
\* ------------------------------------------------------------ */
@media (min-width: 1000px) {
  .account .account__content { padding-left: 100px; padding-right: 100px; }
}
/* ------------------------------------------------------------ *\
  Auth Box
\* ------------------------------------------------------------ */
.auth-box { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 auto; }
.auth-box h3 { font-weight: 500; letter-spacing: 1px; }
.auth-box .auth-box__title { margin-bottom: 56px; }
.auth-box .list-measurements { padding-top: 29px; padding-left: 16px; margin-bottom: 60px; }
.auth-box .list-logos { padding-left: 52px; }
.auth-box .select { -ms-transform: translateX(23px); transform: translateX(23px); }
.auth-box .select.select--transparent { -ms-transform: translateX(0px); transform: translateX(0px); }
.auth-box a.inverted { color: #ff5c36; text-decoration: none; transition: all 0.4s; }
.auth-box a.inverted:hover { color: #313d47; text-decoration: none; }
/*  Section Aside  */
.auth-box .auth-box__aside { width: 51%; padding-right: 90px; padding-left: 43px; }
.auth-box .auth-box__aside h3 { padding-left: 17px; }
/*  Auth Box Content  */
.auth-box .auth-box__content { width: 60%; text-align: center; margin-left: auto;  margin-right: auto;}
.auth-box .auth-box__content--alt { padding-right: 50px; }
.auth-box .auth-box__content--secondary { padding-left: 95px; }
/*  Auth Box 3d  */
.auth-box .auth-box__3d { position: relative; margin-bottom: 101px; }
.auth-box .auth-box__3d img { margin-bottom: 15px; }
/*  Auth Box Fullscreen  */
.auth-box .auth-box__fullscreen { position: absolute; bottom: -1px; right: 1px; }
.auth-box .auth-box__fullscreen i { display: block; }
.auth-box .auth-box__fullscreen:hover { opacity: .8; }
@media (max-width: 1330px) {
  .auth-box .auth-box__content { padding-right: 43px; }
}
.auth-box .auth-box__3d .canvas__container {
  width: 100%;
  max-width: 517px;
  height: 540px;
}
/* Small Desktop */
@media (max-width: 1200px) {
  .auth-box .form { padding-left: 0; }
  .auth-box .list-logos { padding-left: 0; }

  .auth-box .auth-box__aside { padding-right: 50px; padding-left: 0; }

  .auth-box .auth-box__content { padding-right: 0; padding-left: 50px; }

  .auth-box .auth-box__3d { max-width: 517px; }

  .auth-box .list-measurements { padding-top: 0; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
  .auth-box { text-align: center; }
  .auth-box .list-measurements { padding-left: 0; margin-bottom: 30px; }
  .auth-box .list-logos { padding-left: 0; }
  .auth-box .select { -ms-transform: none; transform: none; }
  .auth-box .form { max-width: 517px; padding-left: 0; margin: 0 auto; }

  .auth-box .auth-box__title { padding-left: 0; margin-bottom: 30px; line-height: 1.2; letter-spacing: 1.5px; }

  .auth-box .auth-box__3d { margin-bottom: 50px; }

  .auth-box .auth-box__aside { width: 100%; max-width: 517px; -ms-flex: 0 0 517px; flex: 0 0 517px; padding-right: 0; margin: 0 auto 70px; }
  .auth-box .auth-box__aside h3 { padding-left: 0; }

  .auth-box .auth-box__content { width: 100%; max-width: 600px; -ms-flex: 0 0 517px; flex: 0 0 517px; padding-left: 0; border-left: 0; margin: 0 auto; }
  .auth-box .auth-box__content:before { display: none; }
}

/* ------------------------------------------------------------ *\
	Avatar
\* ------------------------------------------------------------ */
.avatar { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 auto; }
.avatar .list-measurements { padding-top: 29px; padding-left: 16px; margin-bottom: 60px; }
.avatar .list-logos { padding-left: 52px; }
.avatar .select { -ms-transform: translateX(23px); transform: translateX(23px); }
.avatar .select.select--transparent { -ms-transform: translateX(0px); transform: translateX(0px); }
.avatar .form { padding-left: 45px; }
.profile-page h3 {
	margin-bottom: 30px;
	font-weight: 500;
	letter-spacing: 1px;
	text-align: center;
}
.profile-page h3 > i {
	color: #ef7b5c;
	text-decoration: none;
	font-style: normal;
	font-weight: 500;
}
/*  Section Aside  */
.avatar .avatar__aside { width: 51%; padding-right: 90px; padding-left: 43px; }
/*  Avatar Content  */
.avatar .avatar__content { width: 49%; padding-left: 43px; text-align: center; }
.avatar .avatar__content:before { content: ''; position: absolute; top: 104px; bottom: -5px; left: 51.1%; width: 1px; background: #b3b7bb; }
.avatar .avatar__content--alt { padding-right: 50px; }
.avatar .avatar__content--secondary { padding-left: 95px; }
/*  Avatar 3d  */
.avatar .avatar__3d { position: relative; margin-bottom: 101px; }
.avatar .avatar__3d img { margin-bottom: 15px; }
/*  Avatar Fullscreen  */
.avatar .avatar__fullscreen { position: absolute; bottom: -1px; right: 1px; }
.avatar .avatar__fullscreen i { display: block; }
.avatar .avatar__fullscreen:hover { opacity: .8; }
.avatar blockquote { font-size: 14px; line-height: 1.5; margin-bottom: 0px; }
@media (max-width: 1330px) {
	.avatar .avatar__content { padding-right: 43px; }
}
.avatar .avatar__3d .canvas__container {
	width: 100%;
	max-width: 517px;
	height: 540px;
}
/* Small Desktop */
@media (max-width: 1200px) {
	.avatar .form { padding-left: 0; }
	.avatar .list-logos { padding-left: 0; }

	.avatar .avatar__aside { padding-right: 50px; padding-left: 0; }

	.avatar .avatar__content { padding-right: 0; padding-left: 50px; }

	.avatar .avatar__3d { max-width: 517px; }

	.avatar .list-measurements { padding-top: 0; }

	.avatar blockquote { font-size: 14px; line-height: 1.5; margin-bottom: 10px; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.avatar { text-align: center; }
	.avatar .list-measurements { padding-left: 0; margin-bottom: 30px; }
	.avatar .list-logos { padding-left: 0; }
	.avatar .select { -ms-transform: none; transform: none; }
	.avatar .form { max-width: 517px; padding-left: 0; margin: 0 auto; }

	.avatar .avatar__title { margin-bottom: 30px; line-height: 1.2; letter-spacing: 1.5px; }

	.avatar .avatar__3d { margin-bottom: 50px; }

	.avatar .avatar__aside { width: 100%; max-width: 517px; -ms-flex: 0 0 517px; flex: 0 0 517px; padding-right: 0; margin: 0 auto 70px; }

	.avatar .avatar__content { width: 100%; max-width: 600px; -ms-flex: 0 0 517px; flex: 0 0 517px; padding-left: 0; border-left: 0; margin: 0 auto; }
	.avatar .avatar__content:before { display: none; }
}
/* ------------------------------------------------------------ *\
	Blockquote
\* ------------------------------------------------------------ */
.blockquote { margin-bottom: 0; font-size: 30px; line-height: 1.67; font-weight: normal; font-style: italic; letter-spacing: 1.13px; text-align: center; }
.blockquote blockquote { position: relative; margin-bottom: 48px; }
.blockquote blockquote:before,
.blockquote blockquote:after { content: '“'; position: absolute; display: inline-block; width: 47px; height: 34px; font-size: 122px; line-height: 0.75; font-weight: normal; letter-spacing: 6.1px; font-style: italic; pointer-events: none;}
.blockquote blockquote:before { top: -29px; left: -24px; }
.blockquote blockquote:after { right: -40px; bottom: 44px; }
@media (max-width: 1380px) {
	.blockquote { max-width: 1080px; margin: 0 auto; }
}
/* Small Desktop */
@media (max-width: 1200px) {
	.blockquote { max-width: 900px; }
	.blockquote blockquote:before { left: -40px; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.blockquote { font-size: 22px; }
	.blockquote p:not(:last-child) { margin-bottom: 30px; }
	.blockquote blockquote { padding: 0 30px; margin-bottom: 30px; }

	.blockquote blockquote:before,
	.blockquote blockquote:after { font-size: 80px; }

	.blockquote blockquote:before { top: -20px; left: 0; }
	.blockquote blockquote:after { bottom: 20px; right: -10px; }
}
/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */
.box { display: block; height: 100%; padding-top: 119px; padding-bottom: 100px; background-size: cover; background-position: center; color: #fff; }
.box:hover { color: #fff; opacity: .9; }
/*  Box Entry  */
.box .box__entry { max-width: 473px; }
.box .box__entry h2 { position: relative; padding-right: 40px; letter-spacing: 1.3px; text-transform: uppercase; }
.box .box__entry h2:after { content: '+'; position: absolute; top: -2px; right: -6px; font-weight: 500; }
.box .box__entry h2:not(:last-child) { margin-bottom: 30px; }
.box .box__entry p { letter-spacing: 0.3px; }
/*  --left  */
.box--left { padding-right: 93px; padding-left: 50px; }
.box--left .box__entry { margin-left: auto; }
/*  --right  */
.box--right { padding-right: 50px; padding-left: 142px; }
.box--right .box__entry { margin-right: auto; }
/* Small Desktop */
@media (max-width: 1200px) {
	.box { padding-right: 50px; padding-left: 50px; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.box { padding: 50px 25px; }
	.box .box__entry h2 { padding-right: 30px; font-size: 32px; }
}

/* ------------------------------------------------------------ *\
	# Button
\* ------------------------------------------------------------ */
[class^="btn"] {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer; text-decoration: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.btn {
	min-width: 199px;
	height: 60px;
	padding: 0 15px;
	border: 2px solid #ff5c36;
	border-radius: 0;
	background: #ff5c36;
	font-size: 16px;
	line-height: 58px;
	color: #fff;
	font-weight: 500;
	letter-spacing: 0.6px;
	transition: border 0.4s, background 0.4s, color 0.4s;
	text-transform: uppercase;
}

/* .btn-large {
	min-width: 100%;
} */
.btn:focus { box-shadow: none; }
.btn:hover { background: #fff; color: #ff5c36; }
.btn-orange-transparent { border-color: #ff5c36; background: transparent; color: #ff5c36; }
.btn-orange-transparent:hover { border-color: #ff5c36; background: #ff5c36; color: #fff; }
.btn-transparent { background: transparent; }
.btn-transparent:hover { background: #ff5c36; color: #fff; }
.btn-alt { color: #fff !important; }
.btn-alt:hover { color: #ff5c36 !important; }
.btn-white { border-color: #fff; background: #fff; color: #ff5c36; }
.btn-white:hover { border-color: #fff; background: #ff5c36; color: #fff; }
.btn-black { border-color: #303d47; background: #303d47; color: #fff; }
.btn-black:hover { border-color: #ff5c36; background: #ff5c36; color: #fff; }
.btn-grey { border-color: #e2e1e0; background: #e2e1e0; color: #313d47; }
.btn-grey:hover { border-color: #dedede; background: #d6d6d6; color: #313d47; }
.btn-blue { border-color: #0b19a9; background: #0b19a9; color: #fff; }
.btn-blue:hover { border-color: #ff5c36; background: #ff5c36; color: #fff; }
.btn-blue-transparent { border-color: #0b19a9; background: transparent; color: #0b19a9 }
.btn-blue-transparent:hover { border-color: #0b19a9; background: #0b19a9; color: #fff }
.btn-blue-inverse { border-color: #fff; background: #fff; color: #0b19a9; }
.btn-blue-inverse:hover { border-color: #fff; background: #0b19a9; color: #fff; }
.btn-block { display: block; padding-left: 0; padding-right: 0; }
.btn-right { float: right; }
.btn-modal-options { padding-left: 23px; padding-right: 23px; }

/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */
.callout { padding: 75px 0 72px; text-align: center; background-color: #e2e1e024;}
/*.callout .container { max-width: calc(760px + $container-gutter * 2); }*/
.callout .btn { margin-bottom: 40px; }
.callout p { line-height: 1.44; font-weight: 500; letter-spacing: .3px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.callout { padding: 60px 0; }
	.callout .btn { margin-bottom: 30px; }
}
/* ------------------------------------------------------------ *\
	Copyrights
\* ------------------------------------------------------------ */
.copyrights { font-size: 12px; line-height: 1.2; }
.copyrights p { font-weight: normal; letter-spacing: .6px; }
[v-cloak] {
    display: none;
}
#fitvago .section__body {
    min-height: 600px;
    max-width: 600px;
    margin: 0 auto;
}
#fitvago .section__body h4 {
    text-align: center;
}
.list-garments {
	list-style-type: none;
	list-style: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	width: 90%;
	margin: 2em auto 0;
	-ms-flex-pack: start;
	    justify-content: flex-start;
}
.list-garments.my-list {
    width: 75%;
}
.list-garments.my-list li {
    width: 20%;
}
.list-garments li {
	width: 32.333%;
	position: relative;
	padding: 1rem;
	margin-bottom: 1%;
	background-color: #fafbfd;
	border-radius: 2px;
    cursor: pointer;
    margin-right: 1%;
}
.list-garments li img,
.size-chart-details img {
    max-height: 400px;
}
.list-garments .close {
	display: none;
}
.list-garments li h5,
.size-chart-details h5 {
	font-size: 1.12rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    text-align: center
}
.list-garments li h5 .lighter,
.size-chart-details h5 .lighter {
    font-size: 1rem;
    font-weight: normal;
    vertical-align: baseline;
}
.list-garments li img {
	padding: 0.4rem;
}
.list-garments .btn-group-vertical {
    width: 100%;
}
.size-chart-details .size-chart-details-footnote {
  font-size: 10px;
}
/* .list-garments li .description {
    position: absolute;
    bottom: 0.8em;
    width: calc(100% - 30px);
    padding-top:
} */
.list-garments li.selected {
	position: fixed;
	top: 116px;
	left: 0;
	z-index: 49;
	width: 100%;
	height: calc(100vh - 116px);
	overflow: hidden;
	text-align: center;
}
.list-garments li.selected .close {
	display: block;
	position: absolute;
	top: 1rem;
    right: 1rem;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: underline;
}
.list-garments li.selected img {
	height: calc(100vh - 130px);
    width: auto;
    position: relative;
    display: inline-block;
    margin-left: -20%;
    border-right: 1px solid #b3b7bb;
    padding: 0 2rem;
    margin-right: 2rem;
}
.list-garments li.selected > div {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-top: 10%;
}
.list-garments p.size-error {
    color: red;
}
.list-garments .size-alterations-needed {
    color: #c39404;
    font-weight: 500;
}
.list-garments p.size-success {
    color: #28a745;
}
#fitvago .loader-1 span::after{
	border-color: #303d47;
}
.list-garments.empty {
    text-align: center;
    max-width: 75%;
    margin: 0 auto;
}
#fitvago .filter {
    text-align: center;
}
#fitvago .filter .list-radios {
    width: 25%;
    margin: 0;
    -ms-flex-pack: start;
        justify-content: start;
}
#fitvago .filter .list-radios li {
    padding: 0 27px 0 0;
}
#fitvago .filter .list-radios label {
    margin-top: 22px;
}
#fitvago .form__row {
    margin: 2em 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
}
#fitvago [type="submit"] {
    width: 25%;
    vertical-align: middle;
}
#fitvago select {
    width: 30%;
}
#fitvago .chosen-container {
    background: #ecebea;
    padding: 10px;
    border: 2px solid #ecebea;
    margin-right: 10px;
}
#fitvago .chosen-container-multi .chosen-choices .search-field .chosen-search-input[type="text"] {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
}
#fitvago .chosen-container-multi .chosen-choices li.search-choice {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
}
.btn-group-vertical .btn {
    margin-top: 10px;
    margin-bottom: 10px;
}
.my-list .container {
    margin-top: 10px;
}
.my-list .title {
    margin-top: 30px;
}
.size-chart-details-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
}
.size-chart-details-nav .btn {
    height: 40px;
    font-size: 80%;
    line-height: 40px;
    width: 48%;
}
.size-chart-details:not(.main) {
    padding: 1.6em;
}
.size-chart-details .logo-container {
    height: 40vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}
.size-chart-details img {
    padding: 0 0 1em;
    max-width: 280px;
    height: auto;
}
#fitvago .size-bubbles {
    margin: 1.2em 0;
    line-height: 160%;
}
#fitvago .list-garments .size-bubbles {
    line-height: 140%;
    font-size: 0.92rem;
    text-align: center;
    margin-top: 1.2em;
}
#fitvago .bubble {
    display: inline-block;
    vertical-align: text-bottom;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #ff5c36;
    margin: 0 0.3em;
}
#fitvago .list-garments .bubble {
    height: 12px;
    width: 12px;
    margin: 0 4px;
}
#fitvago .bubble-match {
    background-color: #ff5c36;
}
#fitvago .bubble-partial {
    background: #ff5c36;
    background: linear-gradient(90deg, rgba(255,92,54,1) 0%, rgba(255,92,54,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}
#fitvago .bubble-empty {
    background-color: transparent;
}
.size-chart-details table {
    width: 68%;
    border: none;
    margin: 0 auto;
}
.size-chart-details .description {
    text-align: center;
}
.size-chart-details .bootstrap-select.form-control {
    width: 72% !important;
    display: block;
    margin: 0 auto;
}
.size-chart-details .dropdown-toggle {
    margin: 1.2em 0 0.6em;
    height: 40px;
    line-height: 36px;
}
@media (max-width: 1200px) {
    .list-garments li.selected {
        top: 116px;
        height: calc(100vh - 116px);
    }
}
#app {
    max-width: 540px;
    margin: 0 auto;
  }
.brands.list {
    list-style-type: none;
    margin-bottom: 32px;
  }
.brands.list > li {
    display: block;
    border-top: 1px solid #e8e8e8;
    position: relative;
  }
.brands.list > li:last-child {
    border-bottom: 1px solid #e8e8e8;
  }
.brands.list > li .matches {
    overflow: hidden;
    height: 0;
  }
.brands.list > li.open .matches {
    height: auto;
  }
.brands.list > li .label {
    opacity: 0.001;
    font-size: 0.72rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    pointer-events: none;
    -ms-flex-preferred-size: 96px;
        flex-basis: 96px;
    max-width: 96px;
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding: 0 10px;
    -ms-flex-item-align: center;
        align-self: center;
  }
.brands.list > li .label:last-of-type {
    margin-right: 10px;
  }
.brands.list > li.open .label {
    opacity: 0.999;
  }
.brands.list > li.open li {
    border-top: 1px solid #e8e8e8;
    font-size: 0.72rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 3px 0;
  }
.brands.list > li.open li:after {
    content: "";
    display: table;
    clear: both;
  }
.brands.list > li.open li .match {
    display: inline-block;
    max-width: 96px;
    text-align: center;
    vertical-align: middle;
    -ms-flex-preferred-size: 96px;
        flex-basis: 96px;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-item-align: center;
        align-self: center;
  }
.brands.list > li.open li .match.size {
    color: #ff5c36;
  }
.brands.list > li.open li .match.score {
    margin-right: 10px;
  }
.brands.list > li.open li > a {
    display: -ms-flexbox;
    display: flex;
    min-height: 36px;
    position: relative;
    -ms-flex-direction: row;
        flex-direction: row;
  }
.brands.list > li.open li > a:before {
    content: "";
    display: inline-block;
    width: 1px;
    min-height: 36px;
    vertical-align: middle;
  }
.brands.list > li.open li > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    width: 10px;
    background: transparent;
    background-image: url(/assets/images/arrow.svg);
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center center;
  }
.brands.list > li .garm-type {
    font-size: 0.86rem;
    display: inline-block;
    width: 36%;
    vertical-align: middle;
    -ms-flex-item-align: center;
        align-self: center;
    min-width: 112px;
    -ms-flex-preferred-size: 112px;
        flex-basis: 112px;
    -ms-flex-positive: 2;
        flex-grow: 2;
    margin-left: 52px;
  }
.brands.list button.no-style {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
    border: none;
    background: transparent;
    min-height: 60px;
  }
.brands.list button.no-style:focus, .brands.list button.no-style:active {
    outline: none;
  }
.brands.list button.no-style:before {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    height: 10px;
    width: 10px;
    background: transparent;
    background-image: url("/assets/images/arrow.svg");
    background-size: 10px 10px;
    background-position: 0 0;
    transform: rotate3d(0, 0, 0);
    transition: transform 0.125s ease-in-out;
  }
.open .brands.list button.no-style:before {
    transform: rotate3d(0, 0, 90deg);
  }
.brands.list button.no-style .img-wrap {
    text-align: left;
    width: 36%;
    min-width: 112px;
    -ms-flex-preferred-size: 112px;
        flex-basis: 112px;
    -ms-flex-positive: 2;
        flex-grow: 2;
    margin-right: 52px;
  }
.brands.list button.no-style img {
    max-height: 60px;
    max-width: 86px;
    margin: 0;
    padding: 8px 0;
    vertical-align: middle;
    -ms-transform: translateX(calc(43px - 50%));
        transform: translateX(calc(43px - 50%));
    margin-left: 8px;
  }
.brands.list .open button.no-style:before {
    -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
  }
.brands.filter {
    padding: 36px 0;
  }
.brands.filter input[type=search] {
    border: none;
    background: transparent;
    box-shadow: 0 3px 16px rgba(100, 100, 100, 0.2);
    border-radius: 4px;
    font-size: 0.86rem;
    line-height: 36px;
    width: 86%;
    margin: 1.8rem auto 0;
    display: block;
    padding: 0 1rem;
  }
.brands.filter select[name=venue] {
    display: block;
    margin: 0 auto 1.8rem;
    border: none;
    width: 35%;
    min-width: 150px;
    font-size: 0.86rem;
  }
.favorite {
    border: none;
    background-color: transparent;
    background-image: url("/assets/images/star.svg");
    background-size: 16px 16px;
    background-position: center 45%;
    background-repeat: no-repeat;
    vertical-align: top;
    font-size: 0;
    height: 28px;
    width: 28px;
  }
.favorite:focus, .favorite:active {
    outline: none;
  }
.favorite.marked {
    background-image: url("/assets/images/star-filled.svg");
  }
.size-chart h3 {
    font-size: 0.86rem;
    line-height: 28px;
    text-align: center;
    margin: 1.2rem auto;
    text-indent: -28px;
  }
.size-chart .table-wrap {
    font-size: 0;
    border-top: 1px solid #e8e8e8;
    overflow: hidden;
  }
.size-chart table {
    font-size: 0.72rem;
    font-weight: 500;
    text-transform: uppercase;
    width: auto;
    margin-bottom: 0;
  }
.size-chart table td, .size-chart table th {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    padding: 0;
    text-align: center;
    min-width: 100px;
    height: 42px;
  }
.size-chart table td.match, .size-chart table span.match {
    color: #ff5c36;
  }
.size-chart table th {
    background-color: #f5f5f5;
  }
.size-chart table.sticky {
    display: block;
    border-right: 1px solid #e8e8e8;
    max-width: 120px;
    width: 120px;
    float: left;
    margin-right: -1px;
    box-shadow: 3px 0 16px rgba(100, 100, 100, 0.2);
    box-sizing: unset;
    position: relative;
  }
.size-chart table.sticky td.measurement-desc {
    min-width: 0;
    width: 71px;
  }
.size-chart table.sticky td.measurement-val {
    min-width: 0;
    width: 54px;
  }
.size-chart table.sticky th[colspan="2"], .size-chart table.sticky dt[colspan="2"] {
    min-width: 0;
    width: 119px;
    padding: 0 1em;
  }
.size-chart .scrollable-table-wrap {
    width: calc(100% - 120px);
    display: block;
    overflow-x: scroll;
    border-right: 1px solid #e8e8e8;
  }
.size-chart .navbar {
    min-height: 90px;
  }
.size-chart .size-chart-details-explanation {
    padding: 10px;
  }
.navbar a.back {
    height: 30px;
    display: inline-block;
    width: 30px;
    position: absolute;
    top: 16px;
    left: 8px;
  }
.navbar .brand-logo {
    display: block;
    height: 70px;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    top: 16px;
  }
.navbar .brand-logo img {
    max-height: 60px;
    max-width: 120px;
    margin: 0;
  }
.main.size-chart-details .navbar-toggler {
    top: 30px;
}
.main.size-chart-details .navbar-toggler .navbar-toggler-icon {
    background-color: #000;
}
.main.size-chart-details
.navbar-toggler[aria-expanded="true"]
.navbar-toggler-icon:nth-child(2) {
    background-color: transparent;
}
@media (min-width: 1025px) {
	.main.size-chart-details .navbar-collapse { display: none; }
}
/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::-webkit-input-placeholder { color: inherit; opacity: 1; }
input:-ms-input-placeholder { color: inherit; opacity: 1; }
input::placeholder { color: inherit; opacity: 1; }
textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }
textarea:-ms-input-placeholder { color: inherit; opacity: 1; }
textarea::placeholder { color: inherit; opacity: 1; }
input:-webkit-autofill { -webkit-text-fill-color: inherit; -webkit-box-shadow: 0 0 0 1000px #fff inset; }
.field { width: 100%; height: 58px; padding: 0 34px; border: 1px solid #ecebea; border-radius: 0; background: #ecebea; font-size: 14px; color: #313d47; font-weight: 500; letter-spacing: 0.35px; -webkit-appearance: none; -moz-appearance: none; appearance: none; transition: background 0.4s; }
.field:not([readonly]):focus { background: #fff; }
.field--center { text-align: center; }
.field-error, .error { border: 1px solid #e82954; }
.field--height-inches { padding-left: 15px; padding-right: 15px; width: 33%; }
.textarea {}
/*  Select  */
.select { position: relative; display: inline-block; }
.select:after { content: ''; position: absolute; top: 7px; right: 17px; width: 0; height: 0; border-style: solid; border-width: 7px 5.5px 0 5.5px; border-color: #313d47 transparent transparent transparent; pointer-events: none; }
.select select { display: block; min-width: 120px; height: 20px; padding: 0 33px 0 18px; border: 0; border-radius: 10px; background: #a6b5c1; font-size: 13px; color: #303d47; font-weight: 500; letter-spacing: 0.65px; text-transform: uppercase; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.select select::-ms-expand { display: none; }
.select--transparent:after { top: 3px; }
.select--transparent select { border: transparent; background: transparent; }
.select--sm select { min-width: 87px; height: 14px; padding: 0 20px 0 15px; font-size: 10px; line-height: 14px; letter-spacing: 0.5px; }
.select--sm:after { top: 5px; right: 7px; border-width: 5px 4px 0 4px; }
/*  Radio  */
.radio input { display: none; }
.radio label { position: relative; display: block; padding-left: 17px; margin-bottom: 0; font-size: 13px; line-height: 1.2; font-weight: 500; letter-spacing: 0.65px; text-transform: uppercase; }
.radio label:before { content: ''; position: absolute; top: 1px; left: 0; width: 10px; height: 10px; background: #e2e1e0; border-radius: 50%; }
.radio label:after { content: ''; position: absolute; top: 4px; left: 3px; width: 4px; height: 4px; background: transparent; transition: background 0.4s; border-radius: 50%; }
.radio input:checked + label:after { background: #ff5c36; }
/*  Checkbox  */
.checkbox { position: relative; text-align: left; }
.checkbox input { display: none; }
.checkbox label { display: block; padding: 3px 0 1px 56px; margin-bottom: 0; font-size: 12px; line-height: 1.2; font-weight: 500; color: #313d47; letter-spacing: 0.6px; text-transform: uppercase; }
.checkbox label:before { content: ''; position: absolute; top: 0; left: 0; width: 50px; height: 18px; border-radius: 10px; background: #a6b5c1; }
.checkbox label:after { content: ''; position: absolute; top: 0; left: 0; width: 18px; height: 18px; border-radius: 100%; background: #ff5c36; transition: left 0.4s, background 0.4s; }
.checkbox label span:before,
.checkbox label span:after { position: absolute; top: 4px; font-size: 10px; line-height: 1.2; color: #fff; font-weight: 500; letter-spacing: 0.5px; transition: opacity 0.4s, visibility 0.4s; }
.checkbox label span:before { content: 'On'; left: 8px; opacity: 0; visibility: hidden; }
.checkbox label span:after { content: 'Off'; left: 23px; opacity: 1; visibility: visible; }
.checkbox input:checked + label:after { left: 32px; background: #303d47; }
.checkbox input:checked + label span:before { opacity: 1; visibility: visible; }
.checkbox input:checked + label span:after { opacity: 0; visibility: hidden; }
.checkbox--gutter-md { margin-top: 39px; }
/*  Checkbox Alt  */
.checkbox-alt input { display: none; }
.checkbox-alt label { position: relative; display: block; padding-left: 17px; margin-bottom: 0; font-size: 13px; line-height: 1.2; font-weight: 500; letter-spacing: 0.65px; text-transform: uppercase; }
.checkbox-alt label:before { content: ''; position: absolute; top: 1px; left: 0; width: 10px; height: 10px; background: #e2e1e0; }
.checkbox-alt label:after { content: ''; position: absolute; top: 4px; left: 3px; width: 4px; height: 4px; background: transparent; transition: background 0.4s; }
.checkbox-alt input:checked + label:after { background: #ff5c36; }
.checkbox-alt--right label { padding: 5px 35px 0 0; text-align: right; }
.checkbox-alt--md label:before { right: 0; left: auto; width: 16px; height: 16px; }
.checkbox-alt--md label:after { right: 1px; left: auto; width: 14px; height: 14px; }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form { clear: both; text-align: left; position: relative; }
.form .password .field { padding-right: 50px; }
/*  Form Label  */
.form .form__label { display: block; margin-bottom: 11px; font-size: 12px; line-height: 1.2; font-weight: 500; letter-spacing: 0.6px; text-transform: uppercase; }
/*  Form Head  */
.form .form__head { margin-bottom: 20px; }
/*  Form Body  */
.form .form__body .row { margin-right: -24px; margin-left: -24px; }
.form .form__body [class^="col"] { padding: 0 24px; }
/*  Form Row  */
.form .form__row { margin-bottom: 23px; }
.form .form__row--alt { position: relative;  }
.form .form__row--alt label { padding-right: 85px; }
.form .form__row--alt .select { position: absolute; top: -1px; right: 0; }
/*  Form Controls  */
.form .form__controls p { display: block; max-width: 300px; margin-top: 8px; margin-left: auto; font-size: 10px; line-height: 1.2; color: #303d47; font-weight: 500; letter-spacing: 0.5px; text-align: right; text-transform: uppercase; }
.form .form__controls.password { position: relative; }
/*  Form Password  */
.form .form__password { position: absolute; top: 50%; right: 21px; -ms-transform: translateY(-50%); transform: translateY(-50%); width: 21px; height: 13px; }
/*  Form Actions  */
.form .form__actions { margin-top: 59px; }
.form .form__actions:after { content: ''; clear: both; display: table; line-height: 0; }
.form .form__actions .row { margin: 0 -20px; }
.form .form__actions .row .btn { width: 100%; }
.form .form__actions [class^="col"] { padding: 0 20px; }
/*  --signup  */
.form--signup .form__head { margin-bottom: 16px; }
.form--signup .form__row { margin-bottom: 28px; }
.form--signup .form__body .row { margin: 0 -21px; }
.form--signup .form__body [class^="col"] { padding: 0 21px; }
.form--signup .form__actions { margin-top: 90px; }
.form--signup .form__actions { text-align: right; }
/*  --signup-alt  */
.form--signup-alt .form__row { margin-bottom: 47px; }
.form--signup-alt .form__head { margin-bottom: 18px; }
.form--signup-alt .form__body .row { margin-right: -21px; margin-left: -21px; }
.form--signup-alt .form__body [class^="col"] { padding: 0 21px; }
.form--signup-alt .form__actions { margin-top: 111px; }
.form--signup-alt .form__actions { text-align: right; }
.form--signup-alt .form__row--alt { margin-bottom: 32px; }
/*  --signin  */
.form--signin .form__row { margin-bottom: 25px; }
.form--signin .form__body .row { margin-right: -21px; margin-left: -21px; }
.form--signin .form__body [class^="col"] { padding: 0 21px; }
.form--signin .form__actions { margin-top: 60px; }
.form--signin .form__actions { text-align: right; }
/*  --demo  */
.form--demo .form__head { margin-bottom: 46px; }
.form--demo .form__head h3 { text-indent: -2px; }
.form--demo .form__body .row { margin: 0 -50px; }
.form--demo .form__body [class^="col"] { padding: 0 50px; }
.form--demo .form__body { position: relative; }
.form--demo .form__row { margin-bottom: 23px; }
.form--demo .form__actions { margin-top: 48px; text-align: right; }
.form--demo .form__actions .form__btn:hover { border-color: #ff5c36; }
/*  --demo-alt  */
.form--demo-alt .form__head { margin-bottom: 16px; }
.form--demo-alt .form__actions { margin-top: 43px; }
/*  --request  */
.form--request .form__head { margin-bottom: 15px; }
.form--request .form__body { padding-left: 3px; position: relative; }
.form--request .form__actions { margin-top: 42px; }
.form__result, .form__loader {
	position: absolute;
	top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.999;
	background-color: #0f0e9f;
	pointer-events: all;
	transition: opacity 0.25s ease-in-out;
	display: block;
}
.form__result.on-signin, .form__loader.on-signin {
	background-color: #0b19a9;
}
.form__result.hidden, .form__loader.hidden {
	opacity: 0.001;
	pointer-events: none;
	display: block;
}
.form__result > p {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	    transform: translateY(-50%);
	text-align: center;
	width: 100%;
}
.sign_up_form {
	position: relative;
}
.sign_up_form .form__loader,
.password_reset_form .form__loader,
.resend_activation_form .form__loader,
.sign_in_form .form__loader{
	background: #ff653b;
}
#activate-form .form__loader {
	background: #fff;
}
#activate-form .form__loader .loader-1 span::after {
	border-color: #303d47;
}
.form__signup_result {
	position: absolute;
	top: 0;
    width: 100%;
    height: 100%;
    display: block;
	background-color: #0f0e9f;
}
.form__signup_result.on-signup {
	background-color: #ff653b;
}
.form__signup_result.hidden {
	display: none;
}
.form__signup_result > p {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	    transform: translateY(-50%);
	text-align: center;
}
.form.form--subscribe .form__actions {
	margin-top: 10px;
}
.form.form--subscribe .form__status-message {
	margin-top: 10px;
	font-weight: 400;
}
.form-edit-mode input:not([type=submit]):not([type=file]):not([disabled]) {
	background: #f9dcd969;
}
.form .account-status-message { margin-top: 20px; }
.form h3 { text-align: center; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.form--signup-alt .form__row { margin-bottom: 30px; }
}

.form .form__btn__large {
	min-width: 100%;
}

/* ------------------------------------------------------------ *\
	# Icons
\* ------------------------------------------------------------ */
[class^="ico-"] { font-size: 0; line-height: 0; text-indent: -4004px; display: inline-block; vertical-align: top; position: relative; }
.ico-full-screen { width: 21px; height: 22px; background-image: url('/assets/images/full-screen@2x.png'); background-size: 100%; background-position: center; }
.ico-eye { width: 21px; height: 13px; background-image: url('/assets/images/eye@2x.png'); background-size: 100%; background-position: center; }
.ico-eye.orange { background-image: url('/assets/images/eye-orange@2x.png'); }
/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */
.intro { position: relative; display: table; width: 100%; height: 100vh; }
/*  Intro Image  */
.intro .intro__image { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-position: center; background-size: cover; }
/*  Intro Content  */
.intro .intro__content { position: relative; display: table-cell; vertical-align: middle; padding: 70px 0; font-size: 18px; line-height: 1.67; letter-spacing: 0.9px; color: #fff; font-weight: normal; }
.intro .intro__content h1 { margin-bottom: 10px; font-size: 42px; line-height: 1.2; letter-spacing: 2.1px; text-transform: uppercase; }
.intro .intro__content h1:last-child { margin-bottom: 0; }
/*  Intro Entry  */
.intro .intro__entry { max-width: 487px; padding-top: 100px; margin-left: auto; }
.intro .intro__entry p { max-width: 300px;
    padding-left: 3px;
    letter-spacing: .3px;
    font-size: 36px;
    font-weight: 300; }
.intro .intro__entry p:not(:last-child) { margin-bottom: 41px; }
/*  --gutter-bottom  */
/*.intro--gutter-md { margin-bottom: 93px; }*/
/*  --home  */
.intro--home { overflow: hidden; }
.intro--home .container { max-width: 1194px; }
.intro--home .intro__entry { max-width: 550px; padding-top: 110px; margin-left: 0; }
.intro--home .intro__entry h1 { font-size: 60px; line-height: 1.03; color: #0210a0; font-weight: normal; letter-spacing: 3.9px; }
.intro--home .intro__entry h1 strong { display: block; font-weight: bold; }
.intro--home .intro__entry h4 { font-weight: 400; font-size: 24px; color: #313d47; width: 500px; }
.intro--consumers { margin-bottom: 56px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.intro--home .intro__entry h1 { font-size: 55px; }
}

.intro .hero-vid {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 100%;
  max-width: none;
  min-height: 100vh;
  z-index: -100;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  }
@media only screen and (max-width: 1048px) {
  .intro {
    height: 800px;
  }
  .intro .hero-vid {
    min-height: 800px;
    height: 800px;
    width: auto; } }
@media only screen and (max-width: 868px) {
  .intro {
    height: 775px;
  }
  .intro .hero-vid {
    min-height: 775px;
    height: 775px;
    width: auto;  } }
@media only screen and (max-width: 720px) {
  .intro {
    height: 725px;
  }
  .intro .hero-vid {
    min-height: 725px;
    height: 725px;
    width: auto;  } }
@media only screen and (max-width: 600px) {
  .intro {
    height: 700px;
  }
  .intro .hero-vid {
    width: auto;  } }
@media only screen and (max-width: 480px) {
  .intro {
    height: 700px;
  }
  .intro .hero-vid {
    width: auto;  } }
/* ------------------------------------------------------------ *\
	# Lists
\* ------------------------------------------------------------ */
[class^="list-"] > ul { list-style: none; position: relative; }
/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */
.list-links { line-height: 1.75; letter-spacing: .65px; list-style: none; }
/* ------------------------------------------------------------ *\
	List Measurements
\* ------------------------------------------------------------ */
.list-measurements { font-size: 18px; line-height: 1.67; font-weight: 300; letter-spacing: 1px; list-style: none; }
.list-measurements li { display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.list-measurements span { width: 50%; text-transform: uppercase; text-align: right; }
.list-measurements strong { width: 50%; padding-left: 24px; font-weight: normal; color: #ff5c36; text-align: left; }

/* ------------------------------------------------------------ *\
	List Logos
\* ------------------------------------------------------------ */
.list-logos { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 -33px; list-style: none; text-align: left; }
.list-logos li { width: 50%; padding: 0 33px; }
.list-logos li:nth-child(n+3) { margin-top: 53px; }
.list-logos a { border-radius: 15px; }
.list-logos a img { margin-bottom: 16px; transition: opacity 0.4s; }
.list-logos a:hover img { opacity: .7; }
.list-logos p { padding: 0 5px; font-size: 24px; line-height: 1.22; color: #032a4b; font-weight: 300; letter-spacing: 1.2px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.list-logos { max-width: 600px; margin: 0 auto; text-align: center; }
	.list-logos li:nth-child(n+3) { margin-top: 30px; }
}

/* ------------------------------------------------------------ *\
	List Tags
\* ------------------------------------------------------------ */
.list-tags { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 -3px; font-size: 20px; line-height: 1.2; font-weight: normal; letter-spacing: 1px; list-style: none; }
.list-tags:not(:last-child) { margin-bottom: 40px; }
.list-tags li { padding: 5px 3px; }
.list-tags a { display: block; padding: 3px 6px; background: #6467be; color: #fff; }
.list-tags a:hover { background: #7376d2; }

/* ------------------------------------------------------------ *\
	List Info
\* ------------------------------------------------------------ */
.list-info { font-size: 20px; line-height: 1.36; font-weight: 300; letter-spacing: 1.4px; list-style: none; }
.list-info li { position: relative; padding-left: 50px; }
.list-info li:not(:last-child) { margin-bottom: 24px; }
.list-info li:before { content: '+'; position: absolute; top: 3px; left: -3px; font-size: 28px; line-height: 0.71; color: #fff; font-weight: 500; }

/* ------------------------------------------------------------ *\
	List Radios
\* ------------------------------------------------------------ */
.list-radios { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-pack: center; justify-content: center; margin: 0 -25px; list-style: none; }
.list-radios li { padding: 0 27px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.list-radios { -ms-flex-pack: start; justify-content: flex-start; }
}
/* ------------------------------------------------------------ *\
	List Checkboxes
\* ------------------------------------------------------------ */
.list-checkboxes { list-style: none; }
.list-checkboxes li:not(:last-child) { margin-bottom: 17px; }

/* ------------------------------------------------------------ *\
	List Numbers
\* ------------------------------------------------------------ */
.list-numbers { list-style: none; text-align: left; }
.list-numbers li { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.list-numbers li.width-md { max-width: 530px; }
.list-numbers li:not(:last-child) { margin-bottom: 102px; }
.list-numbers strong { width: 215px; font-size: 92px; line-height: 0.75; color: #303d47; font-weight: 900; letter-spacing: 0; }
.list-numbers p { width: calc(100% - 215px); font-size: 18px !important; line-height: 1.45 !important; color: #313d47; font-weight: 500 !important; letter-spacing: 0.3px !important; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.list-numbers li { max-width: 100% !important; }
	.list-numbers li:not(:last-child) { margin-bottom: 40px; }
	.list-numbers p { width: 100%; max-width: 500px; }
}
/* ------------------------------------------------------------ *\
	List Colors
\* ------------------------------------------------------------ */
.list-colors { list-style: none; }
.list-colors li { position: relative; padding-left: 33px; font-size: 23px; line-height: 1.65; letter-spacing: 0.1px; }
.list-colors li:not(:last-child) { margin-bottom: 1px; }
.list-colors span { position: absolute; top: 8px; left: 0; width: 20px; height: 20px; }
.list-colors p { font-weight: 400; letter-spacing: .65px; }

/* ------------------------------------------------------------ *\
	List Brands
\* ------------------------------------------------------------ */
.list-brands {
	list-style-type: none;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	border-top: 1px solid #eee;
	border-left: 1px solid #eee;
	margin: 0 -15px 0 -15px;
}
.list-brands li:nth-child(even) {
	background-color: #f8f9fa7a;
}
.list-brands li:nth-child(8n+1),
.list-brands li:nth-child(8n+3),
.list-brands li:nth-child(8n+6),
.list-brands li:nth-child(8n) {
    background-color:#f8f9fa7a
}
.list-brands li:nth-child(8n+2),
.list-brands li:nth-child(8n+4),
.list-brands li:nth-child(8n+5),
.list-brands li:nth-child(8n+7) {
    background-color: #fff;
}
.list-brands li {
	width: 25%;
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
	position: relative;
	text-align: center;
	vertical-align: middle;
	font-size: 0;
}
.list-brands li::before {
	content: '';
    padding-top: 100%;
    vertical-align: middle;
	display: inline-block;
	width: 0;
}
.list-brands li img {
	max-width: 75%;
	margin-bottom: 40px;
}
.list-brands li p {
	position: absolute;
	bottom: 0;
	width: 100%;
	margin-bottom: 18%;
	font-size: 8pt;
	padding: 0 30px;
}
@media (max-width: 2000px) {
	.list-brands li {
		width: 25%;
	}

	.list-brands li:nth-child(odd) {
		background-color: #f8f9fa7a;
	}

	.list-brands li:nth-child(even) {
		background-color: #fff;
	}
}
@media (max-width: 1440px) {
	.list-brands li {
		width: 33.33%;
	}

	.list-brands li p {
		margin-bottom: 12%;
	}
}

.brand-details {
	background-color: #f4f3f2;
}
.brand-details > * {
	vertical-align: top;
}
.brand-details > img {
	display: block;
	width: 25%;
	height: auto;
	margin: 0 auto;
	padding: 3em 0 0 0;
}
.brand-details .brand-message {
	width: 70%;
	padding: 3em 3em 6em;
	margin: 0 auto;
}
.brand-details .brand-message .centered {
	margin-top: 6em;
}
.brand-details > a {
	display: inline-block;
	vertical-align: top;
	min-width: none;
	width: 45px;
	height: 45px;
	text-indent: -1000em;
	margin: 60px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+PHBhdGggZD0iTTkuMTA1IDBBOS4wNjkgOS4wNjkgMCAwIDAgMCA5LjA3djQxLjg2MkE5LjA2OSA5LjA2OSAwIDAgMCA5LjA2OSA2MEg1MC45M2M1LjAxIDAgOS4wNy00LjA2IDkuMDctOS4wNjlWOS4wN0M2MCA0LjA2IDU1Ljk0IDAgNTAuOTMxIDB6bTEzLjYzOCAxNi41MjVsNy4yNiA3LjI5MSA3LjI5LTcuMjljMy45LTQuMDQ0IDkuODk1IDEuOTUxIDUuODUgNS44NWwtNy4yNTkgNy4yOSA3LjI2IDcuMjkxYzQuMDQ0IDMuODktMS45NiA5Ljg2My01Ljg1IDUuODJsLTcuMjkxLTcuMjYtNy4yNiA3LjI5MWMtMy45MjEgNC4wMTMtOS44OTQtMS45Ni01Ljg1LTUuODVsNy4yNTktNy4yOTItNy4yNi03LjI5Yy00LjE5LTQuMTkgMS43NzYtOS44MDQgNS44NTEtNS44NXoiLz48L3N2Zz4=);
	background-size: cover;
	opacity: 0.16;
	float: right;
}

/* ------------------------------------------------------------ *\
	List Awards
\* ------------------------------------------------------------ */
.list-awards {
	list-style-type: none;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin: 0 -15px 0 -15px;
	padding-left: 100px;
	padding-right: 100px;
}
.list-awards li {
	width: 33%;
	position: relative;
	text-align: center;
	vertical-align: middle;
	font-size: 0;
}
.list-awards li::before {
	content: '';
  padding-top: 100%;
  vertical-align: middle;
	display: inline-block;
	width: 0;
}
.list-awards li img {
	max-width: 190px;
	margin-bottom: 40px;
	-moz-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	filter: grayscale(100%);
}
.list-awards li p {
	position: absolute;
	bottom: 0;
	width: 100%;
	margin-bottom: 18%;
	font-size: 8pt;
	padding: 0 30px;
}
@media (max-width: 2000px) {
	.list-awards li {
		width: 25%;
	}
}
@media (max-width: 1440px) {
	.list-awards li {
		width: 33.33%;
	}

	.list-awards li p {
		margin-bottom: 12%;
	}
}

/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */
.list-partners {
	list-style-type: none;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin: 0 -15px 0 -15px;
	padding-left: 100px;
	padding-right: 100px;
}
.list-partners li {
	width: 33%;
	position: relative;
	text-align: center;
	vertical-align: middle;
	font-size: 0;
	opacity: 0.8;
}
.list-partners li::before {
	content: '';
  padding-top: 100%;
  vertical-align: middle;
	display: inline-block;
	width: 0;
}
.list-partners li img {
	max-width: 190px;
	margin-bottom: 40px;
	-moz-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	filter: grayscale(100%);
}
.list-partners li img:hover {
	filter: none;
}
.list-partners li:hover {
	opacity: 1;
}
.list-partners li p {
	position: absolute;
	bottom: 0;
	width: 100%;
	margin-bottom: 18%;
	font-size: 8pt;
	padding: 0 30px;
}
@media (max-width: 2000px) {
	.list-partners li {
		width: 25%;
	}
}
@media (max-width: 1440px) {
	.list-partners li {
		width: 33.33%;
	}

	.list-partners li p {
		margin-bottom: 12%;
	}
}

/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */
.list-counter ol {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}
.list-counter ol li {
  margin: 0 0 3.5rem 2rem;
  counter-increment: list-counter;
  position: relative;
}
.list-counter ol li::before {
  content: counter(list-counter);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 40px;
  left: calc(-1 * var(--size) - 40px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: #ff5c36;
  border-radius: 50%;
  text-align: center;
}

/* stylelint-disable */
.loader-1 {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
    margin: auto;
	height: 64px;
	width: 64px;
	animation: loader-1-1 4.8s linear infinite;
}
@keyframes loader-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-1 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 64px;
	width: 64px;
	clip: rect(0, 64px, 64px, 32px);
	animation: loader-1-2 1.2s linear infinite;
}
@keyframes loader-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader-1 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 64px;
	width: 64px;
	clip: rect(0, 64px, 64px, 32px);
	border: 6px solid #FFF;
	border-radius: 50%;
	animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}
/* stylelint-enable */
/* ------------------------------------------------------------ *\
	# Logos
\* ------------------------------------------------------------ */
[class^="logo"] { font-size: 0; line-height: 0; text-indent: -4004px; position: relative; display: inline-block; vertical-align: middle; text-decoration: none; }
[class^="logo"] > img { display: block; width: 100%; }
[class^="logo"]:hover { opacity: 0.75; }
.logo { max-width: 91px; -ms-flex: 0 0 91px; flex: 0 0 91px; }

/* ------------------------------------------------------------ *\
	# Navigations
\* ------------------------------------------------------------ */
[class^="nav"] > ul { display: -ms-flexbox; display: flex; list-style: none; }
[class^="nav"] > ul > li { position: relative; }
[class^="nav"] > ul a { display: block; text-decoration: none; }
.navbar { position: static; display: -ms-flexbox; display: flex; padding: 0; }
/*  Navbar Brand  */
.navbar-brand { position: relative; max-width: 212px; -ms-flex: 0 0 212px; flex: 0 0 212px; padding: 0; margin: 0 10px; }
.navbar-brand:hover { opacity: .8; }
.navbar-brand img { display: block; opacity: 1; visibility: visible; transition: opacity 0.4s, visibility 0.4s; }
.navbar-brand img + img { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; opacity: 0; visibility: hidden; }
/*  Navbar Collapse  */
.navbar-collapse { display: -ms-flexbox; display: flex; -ms-flex-pack: end; justify-content: flex-end; -ms-flex-align: center; align-items: center; -ms-flex-preferred-size: auto; flex-basis: auto; }
/*  Navbar Nav  */
.navbar-nav { display: -ms-flexbox; display: flex; -ms-flex-pack: end; justify-content: flex-end; -ms-flex-align: center; align-items: center; -ms-flex-direction: row; flex-direction: row; width: 100%; padding-top: 4px; margin-right: 21px; font-size: 16px; line-height: 1.2; font-weight: 500; letter-spacing: 0.6px; text-transform: uppercase; }
.navbar-nav li { padding: 0 24px; }
.navbar-nav a { color: #fff; }
.navbar-nav a:hover,
.navbar-nav a.active { color: #ff5c36; }
/* Toggle */
.navbar-toggler { position: absolute; top: 50%; right: 25px; z-index: 60; -ms-transform: translateY(-50%); transform: translateY(-50%); display: none !important; width: 30px; height: 30px; padding: 0; border: 0; }
.navbar-toggler:focus { outline: none; }
.navbar-toggler span { transition: background 0.4s, margin 0.4s, transform 0.4s; }
.navbar-toggler span,
.navbar-toggler span:first-child,
.navbar-toggler span:last-child { position: absolute; left: 0; right: 0; top: 50%; margin-top: -1px; height: 3px; background-color: #fff; }
.navbar-toggler span:first-child { margin-top: -9px; }
.navbar-toggler span:last-child { margin-top: 7px; }
.navbar-toggler[aria-expanded="true"] span { background-color: transparent; }
.navbar-toggler[aria-expanded="true"] span:first-child,
.navbar-toggler[aria-expanded="true"] span:last-child { margin-top: -1px; background: #313d47; }
.navbar-toggler[aria-expanded="true"] span:first-child { -ms-transform: rotate(45deg); transform: rotate(45deg); }
.navbar-toggler[aria-expanded="true"] span:last-child { -ms-transform: rotate(-45deg); transform: rotate(-45deg); }
.navbar-toggler { display: block; }
.navbar-toggler[aria-expanded="true"] + .navbar-collapse { opacity: 1; visibility: visible; }
/* Small Desktop */
@media (max-width: 1200px) {
	.navbar-nav { margin: 0; }
	.navbar-nav li { padding: 0 15px; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.navbar { padding-right: 60px; }
	.navbar-collapse { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 55; -ms-flex-pack: center; justify-content: center; -ms-flex-wrap: wrap; flex-wrap: wrap; height: auto !important; padding: 80px 0; background: #fff; overflow-y: auto; -webkit-overflow-scrolling: touch; opacity: 0; visibility: hidden; transition: opacity 0.4s, visibility 0.4s; pointer-events: none; }
	.navbar-collapse .btn { color: #313d47; }
	.navbar-collapse.collapse.show { display: -ms-flexbox; display: flex; pointer-events: all; }
	.navbar-nav { -ms-flex-pack: center; justify-content: center; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: 0; margin-bottom: 20px; font-size: 20px; text-align: center; }
	.navbar-nav li { width: 100%; padding: 15px 0; }
	.navbar-nav a { display: inline-block !important; padding: 5px 15px; color: #313d47; }

	.navbar-toggler { display: block !important; }
}

/* ------------------------------------------------------------ *\
	Profiles
\* ------------------------------------------------------------ */
.profiles { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 -11px; }
.profiles .profile { width: 25%; padding: 0 11px; }
.profiles .profile:nth-child(n+5) { margin-top: 18px; }
.profile { color: #fff; }
.profile h4 { margin-bottom: 0; }
.profile span { display: block; max-width: 220px; margin: 0 auto; font-size: 12px; line-height: 1.4; letter-spacing: 0.6px; color: #ff5c36; text-transform: uppercase; }
/*  Profile Inner  */
.profile .profile__inner { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; height: 100%; padding: 59px 20px 46px; background: #e2e1e0; font-size: 14px; line-height: 1.64; font-weight: 300; letter-spacing: 0.7px; text-align: center; }
/*  Profile Wrapper  */
.profile .profile__wrapper { width: 100%; }
/*  Profile Image  */
.profile .profile__image { max-width: 225px; width: 100%; height: 225px; border-radius: 100%; margin: 0 auto 25px; background-size: 100%; background-position: center; background-repeat: no-repeat; }
/*  Profile Content  */
.profile .profile__content h4 { margin-bottom: 6px; color: #313d47; }
/*  Profile Overlay  */
.profile .profile__overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; padding: 55px 20px; background: #4a545d; opacity: 0; visibility: hidden; transition: opacity 0.4s, visibility 0.4s; pointer-events: none; }
/*  Profile Overlay Inner  */
.profile .profile__overlay-inner { width: 100%; }
.profile .profile__overlay-inner span { margin-bottom: 35px; }
.profile .profile__overlay-inner span:last-child { margin-bottom: 0; }
/*  --main  */
.profile--main .profile__inner { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; height: 100%; padding-top: 52px; background: #313d47; }
.profile--main .profile__content { max-width: 255px; margin: 0 auto; color: #fff; }
.profile--main .profile__content h2 { margin-bottom: 7px; }
.profile--main .profile__content p { line-height: 1.7; font-weight: normal; letter-spacing: .35px; }
.profile--main .profile__content span { margin-bottom: 40px; }
.profile--main .profile__content span:last-child { margin-bottom: 0; }
@media (min-width: 1025px) {
	.profile .profile__inner:hover .profile__overlay { opacity: 1; visibility: visible; }
}
/* Tablet */
@media (max-width: 1024px) {
	.profiles .profile { width: 50%; }
	.profiles .profile:nth-child(n+3) { margin-top: 18px; }

	.profile.hover .profile__overlay { opacity: 1; visibility: visible; }
}

#promotions h4 {
    text-align: center;
}
.promo-card {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    color: #fff;
    position: relative;
}
.promo-card img {
    display: block;
    width: 100%;
    max-width: 480px;
    height: auto;
    margin: 0 auto;
}
.promo-card .card-amount {
    position: absolute;
    font-size: 2.125rem;
    top: 2rem;
    left: 2.2rem;
}
.promo-card .card-amount-desc {
    position: absolute;
    font-size: 0.72rem;
    top: 5.3rem;
    left: 2.2rem;
    font-weight: 500;
}
.promo-card div.card-expiration-date {
    position: absolute;
    font-size: 0.72rem;
    font-weight: 500;
    bottom: 1.6rem;
    right: 2.2rem;
    text-align: right;
}
.promo-card div.card-expiration-date .card-expiration-date {
    color: #ff5c36;
}
.promo-card .card-coupon-code {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 7px;
}
.promo-card .card-coupon-desc  {
    position: absolute;
    right: 110%;
    font-size: 0.72rem;
    font-weight: 500;
    width: 300px;
    text-align: right;
    top: 50%;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
}
#promotions .card-details {
    font-size: 0.72rem;
    padding: 0 14px;
    margin: 3em auto 0;
    width: 100%;
    max-width: 480px;
}
#promotions .card-details img {
    float: left;
    width: 60px;
    margin: -6px 8px 0 -6px;
}
#promotions .card-details h6 {
    font-size: 14px;
    margin: 0 0 0.3em;
    line-height: 1.1;
    text-transform: uppercase;
}
#promotions .card-details button {
    width: 100%;
    display: block;
    margin: 3em auto 0;
    border: 1px solid rgb(49, 61, 71);
    background: #fff;
    padding: 0.4em;
    font-size: 0.72rem;
}
.rebelle-start .row {
    height: 600px;
}
.rebelle-start .col-sm {
    padding: 3em;
}
.rebelle-start .col-sm:last-child:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background-color: #0b19a9;
}

/* ------------------------------------------------------------ *\
	Requests
\* ------------------------------------------------------------ */
/*  Requests Head  */
.requests .requests__head { padding: 11px 30px; background: #ef7b5c; text-align: center; }
.requests .requests__head h4 { font-size: 30px; line-height: 1.2; color: #fff; font-weight: normal; letter-spacing: 1.2px; text-transform: uppercase; }
/*  Requests Body  */
.requests .requests__body { padding: 21px 50px 26px; background: #ecebea; width: 100%; }
.requests .requests__body .list-tags { max-width: 375px; }
.requests .requests__body.chosen-container {
	font-family: "Futura BT", sans-serif;
}
.requests .requests__body .chosen-choices {
	border: none;
    box-shadow: none;
    background: transparent;
    margin: 0;
    padding: 0;
}
.requests .requests__body .chosen-choices .search-choice {
	background-image: none;
    background-color: #6467be;
    color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 1px;
	padding: 3px 24px 3px 6px;
	margin: 3px 5px 3px 0;
}
.requests .requests__body .chosen-choices .search-field {
	margin: 3px 5px 3px 0;
}
.requests .requests__body .chosen-choices .search-choice .search-choice-close {
	width: 23px;
	height: 30px;
	top: 0px;
	right: 0px;
	transition: none;
	background: none !important;
	opacity: 0.7;
}
.requests .requests__body .chosen-choices .search-choice .search-choice-close:hover {
	opacity: 1;
}
.requests .requests__body .chosen-choices .search-choice .search-choice-close::before {
	content: '\00d7';
    position: absolute;
    width: 18px;
    height: 18px;
    font-size: 16px;
	color: #fff;
    right: 3px;
    top: 7px;
    line-height: 1;
    font-weight: bold;
}
.requests .requests__body.chosen-container-multi .chosen-choices .search-field .chosen-search-input[type="text"] {
	font-family: "Futura BT", sans-serif;
    height: 30px;
	padding: 0;
	margin: 0;
    border: none;
    border-radius: 0;
    background: #ecebea;
    font-size: 18px;
    line-height: 30px;
    color: #313d47;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 1.2px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.requests .requests__body.chosen-container-multi .chosen-choices .search-field .chosen-search-input.default[type="text"] {
	width: 100%;
}
.requests .requests__body.chosen-container-multi .chosen-drop {
	left: 0;
    border: none;
    box-shadow: none;
}
.requests .requests__body.chosen-container-multi .chosen-drop .chosen-results li {
	height: 58px;
    padding: 0 35px;
    margin-top: 1px;
    background: #f4f3f2;
    line-height: 58px;
    cursor: pointer;
	transition: background 0.4s;
	text-align: left;
	font-size: 14px;
	font-weight: 700;
	font-style: italic;
	color: #313d47;
}
.requests .requests__body.chosen-container-multi .chosen-drop .chosen-results li:hover {
	background: #f7f7f7;
	color: #313d47;
}
.requests .requests__body.chosen-container-multi .chosen-drop .chosen-results li.highlighted {
	background: #f7f7f7;
	color: #313d47;
}
@media (min-width: 1000px) {
  .requests .requests__content { padding-left: 100px; padding-right: 100px; }
}

@media (max-width: 2000px) {
    .requests .requests__content { min-height: 540px; }
}
/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */
.search { position: relative; text-align: left; }
.search:not(:last-child) { margin-bottom: 78px; }
/*  Search Field  */
.search .search__field { width: 100%; height: 58px; padding: 0 75px 0 20px; border: 1px solid #ecebea; border-radius: 0; background: #ecebea; font-size: 24px; line-height: 1.2; color: #313d47; font-weight: 500; font-style: italic; letter-spacing: 1.2px; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
/*  Search Button  */
.search .search__btn { position: absolute; top: 0; right: 0; width: 75px; height: 58px; border: 0; border-radius: 0; background: url(/assets/images/search@2x.png) no-repeat center; background-size: 22px 22px; font-size: 0; -webkit-appearance: none; -moz-appearance: none; appearance: none; transition: opacity 0.4s; }
.search .search__btn:hover { opacity: .7; }
/*  Search Dropdown  */
.search__dropdown ul { font-size: 14px; line-height: 1.2; color: #313d47; font-weight: bold; font-style: italic; letter-spacing: 0.45px; list-style: none; }
.search__dropdown li { height: 58px; padding: 0 35px; margin-top: 1px; background: #f4f3f2; line-height: 58px; cursor: pointer; transition: background 0.4s; }
.search__dropdown li:hover { background: #f7f7f7; }

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
section[class^="section"]:first-child { margin-top: 124px; }
.section { padding-bottom: 113px; font-weight: 300; padding-top: 60px;}
.section h2 { color: #ff5c36; text-transform: uppercase; }
/*  Section Head  */
.section .section__head { margin-bottom: 53px; text-align: center; }
.section .section__head p { letter-spacing: .05px; }
/*  Section Image */
.section .section__image { width: 50%; }
@media (max-width: 1024px) {
	section[class^="section"]:first-child { margin-top: 105px; }

	.section { padding-bottom: 60px; }
}

/* ------------------------------------------------------------ *\
	Section Businesses
\* ------------------------------------------------------------ */
.section-businesses { padding-bottom: 62px; font-weight: normal; }
/*  Section Head  */
.section-businesses .section__head { margin-bottom: 75px; text-align: center; }
.section-businesses .section__head .container { max-width: 1230px; }
.section-businesses .section__head h2 { line-height: 1.2; color: #0f0e9f; font-weight: 500; letter-spacing: 1.1px; }
/*  Section Body  */
.section-businesses .section__body p { max-width: 465px; font-size: 23px; line-height: 1.47; font-weight: normal; letter-spacing: .5px; }
.section-businesses .section__body p strong { font-weight: bold; }
/*  Section Foot  */
.section-businesses .section__foot { margin-top: 70px; text-align: center; }
.section-businesses .section__foot .container { max-width: 1000px; }
.section-businesses .section__foot h4 { font-size: 22px; line-height: 1.4; color: #313d47; letter-spacing: .55px; }
/*  --alt  */
.section-businesses--alt .section__head { margin-bottom: 71px; }
.section-businesses--alt .section__head .container { max-width: 930px; }
.section-businesses--alt .section__foot .container { max-width: 1150px; }
.section-businesses--alt .section__foot h4 { letter-spacing: .62px; }
/* Tablet */
@media (max-width: 1024px) {
	.section-businesses { padding-bottom: 60px; }
	.section-businesses .section__head { margin-bottom: 50px; }

	.section-businesses .section__foot { margin-top: 50px; }
}

/* ------------------------------------------------------------ *\
	Section Legal
\* ------------------------------------------------------------ */
.section-legal { padding-bottom: 62px; font-weight: normal; }
/*  Section Head  */
.section-legal .section__head { margin-bottom: 75px; text-align: center; }
.section-legal .section__head .container { max-width: 1230px; }
.section-legal .section__head h2 { line-height: 1.2; color: #0f0e9f; font-weight: 500; letter-spacing: 1.1px; }
/*  Section Body  */
.section-legal .section__body p { font-size: 23px; line-height: 1.47; font-weight: normal; letter-spacing: .5px; }
.section-legal .section__body p strong { font-weight: bold; }
/*  Section Foot  */
.section-legal .section__foot { margin-top: 70px; text-align: center; }
.section-legal .section__foot .container { max-width: 1000px; }
.section-legal .section__foot h4 { font-size: 22px; line-height: 1.4; color: #313d47; letter-spacing: .55px; }
/*  --alt  */
.section-legal--alt .section__head { margin-bottom: 71px; }
.section-legal--alt .section__head .container { max-width: 930px; }
.section-legal--alt .section__foot .container { max-width: 1150px; }
.section-legal--alt .section__foot h4 { letter-spacing: .62px; }
/* Tablet */
@media (max-width: 1024px) {
	.section-legal { padding-bottom: 60px; }
	.section-legal .section__head { margin-bottom: 50px; }

	.section-legal .section__foot { margin-top: 50px; }
}

/* ------------------------------------------------------------ *\
	Section Profiles
\* ------------------------------------------------------------ */
.section-profiles { padding-bottom: 103px; }
@media (max-width: 1024px) {
	.section-profiles { padding-bottom: 60px; }
}

/* ------------------------------------------------------------ *\
	Section Split
\* ------------------------------------------------------------ */
.section-split { padding-bottom: 112px; }
.section-split .row { margin-right: -12px; margin-left: -12px; }
.section-split [class^="col"] { padding: 0 12px; }
/* Mobile */
@media (max-width: 1024px) {
	.section-split { padding-bottom: 0; }
}
/* ------------------------------------------------------------ *\
	Section Background
\* ------------------------------------------------------------ */
.section-background { padding: 109px 0 70px; font-weight: normal; }
/*  Section Head  */
.section-background .section__head { margin-bottom: 109px; text-align: center; }
.section-background .section__head h2 { color: #0f0e9f; font-weight: 500; letter-spacing: 1.2px; }
/*  Section Body  */
.section-background .section__body .container { max-width: 1190px; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
/*  Section Foot  */
.section-background .section__foot { margin-top: 82px; text-align: center; }
.section-background .section__foot .container { max-width: 1000px; }
.section-background .section__foot h4 { font-size: 22px; line-height: 1.37; color: #313d47; letter-spacing: .6px; }
/*  Section Aside  */
.section-background .section__aside { width: 47%; }
/*  Section Content  */
.section-background .section__content { width: 53%; padding: 40px 0 89px 104px; }
/*  --gutter  */
.section-background--gutter { margin-bottom: 91px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.section-background { padding: 60px 0; margin-bottom: 60px; }
	.section-background .section__head { margin-bottom: 35px; }

	.section-background .section__body .container { -ms-flex-align: start; -ms-grid-row-align: flex-start; align-items: flex-start; }

	.section-background .section__aside { width: 50%; }

	.section-background .section__content { width: 50%; padding: 30px 0 30px 30px; }

	.section-background .section__foot { margin-top: 30px; }
}

/* ------------------------------------------------------------ *\
	Section Demo
\* ------------------------------------------------------------ */
.section-demo { padding: 69px 0 90px; color: #fff; }
/*  Section Body  */
.section-demo .section__body .container { max-width: 886px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.section-demo { padding: 50px 0; }
}
/* ------------------------------------------------------------ *\
	Section Intro
\* ------------------------------------------------------------ */
.section-intro { padding: 20px 0 0px; }
/*  Section Head  */
.section-intro .section__head { margin-bottom: 64px; }
.section-intro .section__head .container { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; max-width: 990px; }
.section-intro .section__head .logo { margin-right: 46px; }
.section-intro .section__head p { margin-top: 10px; letter-spacing: 0.1px; font-weight: 400; font-size: 23px;}
/*  Section Body  */
.section-intro .section__body .row { margin: 0 -12px; }
.section-intro .section__body [class^="col"] { padding: 0 12px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.section-intro { padding: 20px 0 60px; }

	.section-intro .section__body .row { margin: 0 -6px; }
	.section-intro .section__body [class^="col"] { padding: 0 6px; }
}

/* ------------------------------------------------------------ *\
	Section Intro panel
\* ------------------------------------------------------------ */
.section-intro-panel { padding-bottom: 62px; font-weight: normal; }
/*  Section Head  */
.section-intro-panel .section__head { margin-bottom: 75px; text-align: center; }
.section-intro-panel .section__head .container { max-width: 1230px; }
.section-intro-panel .section__head h2 { line-height: 1.2; color: #0f0e9f; font-weight: 500; letter-spacing: 1.1px; }
/*  Section Body  */
.section-intro-panel .section__body p { max-width: 465px; font-size: 25px; line-height: 1.47; font-weight: normal; letter-spacing: .5px; margin-bottom: 15px;}
.section-intro-panel .section__body p strong { font-weight: bold; }
.section-intro-panel .section__body .section__quote-name { font-size: 19px; }
/*  Section Foot  */
.section-intro-panel .section__foot { margin-top: 70px; text-align: center; }
.section-intro-panel .section__foot .container { max-width: 1000px; }
.section-intro-panel .section__foot h4 { font-size: 22px; line-height: 1.4; color: #313d47; letter-spacing: .55px; }
/*  --alt  */
.section-intro-panel--alt .section__head { margin-bottom: 71px; }
.section-intro-panel--alt .section__head .container { max-width: 930px; }
.section-intro-panel--alt .section__foot .container { max-width: 1150px; }
.section-intro-panel--alt .section__foot h4 { letter-spacing: .62px; }
/* Tablet */
@media (max-width: 1024px) {
	.section-intro-panel { padding-bottom: 60px; }
	.section-intro-panel .section__head { margin-bottom: 50px; }

	.section-intro-panel .section__foot { margin-top: 50px; }

	.section-intro-panel .section__body .section__quote { font-size: 19px; margin-bottom: 30px; }
	.section-intro-panel .section__body .section__quote-name { font-size: 15px; }
}

/* ------------------------------------------------------------ *\
	Section Quote
\* ------------------------------------------------------------ */
.section-quote { padding: 50px 0 84px; }
.section-quote .container { max-width: 1280px; }
/* Tablet Portrait */
@media (max-width: 1024px) {
	.section-quote { padding: 20px 0 60px; }
}
/* ------------------------------------------------------------ *\
	Section Video
\* ------------------------------------------------------------ */
.section-video { position: relative; padding-top: 56.25%; background-size: cover; background-position: center; }
.section-video .video { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 5; padding-top: 0; }
.section-video .video .video__content { top: 0; bottom: 0; -ms-transform: none; transform: none; padding-top: 0; }

/* ------------------------------------------------------------ *\
	Section Brands
\* ------------------------------------------------------------ */
.section-brands .section-brands__subheading {
	text-align: center;
	margin-bottom: 65px;
	font-weight: normal;
}
@media (max-width: 2000px) {
    .section-brands .section__body { min-height: 400px; }
}
/* ------------------------------------------------------------ *\
	Section Benefits
\* ------------------------------------------------------------ */
.section-benefits { padding-bottom: 0px; }
/* ------------------------------------------------------------ *\
	Signin
\* ------------------------------------------------------------ */
/* .signin { height: 100%; background: #0b19a9; color: #fff; } */
.signin .list-info { margin-top: 13px; margin-bottom: 56px; }
.signin .form {
	max-width: 380px;
	margin: 0 auto;
}
/*  Signin Inner  */
.signin .signin__inner {
	max-width: 650px;
	width: 80%;
	margin: 0 auto;
}
/*  Signin Head  */
.signin .signin__head {
	padding: 36px 0 36px 0;
	background-size: cover;
	background-position: center;
	text-align: left;
}
.signin .signin__head h2 {
	font-size: 30px;
	line-height: 1.19;
	font-weight: normal;
	letter-spacing: 2.1px;
	text-transform: uppercase;
}
/*  Signin Body  */
.signin .iti.iti--allow-dropdown { width: 100%; }

.intl-tel-input {
	width: 100%;
}

.signin .signin__body { padding: 30px 0 93px 0; }
.signin .signin__body .form:not(:first-child) { padding-top: 66px; }
.signin .signin__body .list-radios { -ms-transform: translateX(6px); transform: translateX(6px); padding-bottom: 13px; margin-top: 28px; }
.signin .signin__body .list-checkboxes { padding-top: 15px; }
/*  Signin Actions  */
.signin .signin__actions { margin-top: 56px; }
/* .signin .signin__actions .row { margin: 0 -19px; } */
.signin .signin__actions [class^="col"] {
	text-align: center;
}
.signin .signin__actions [class^="col"] .btn {
	display: inline-block;
}
.signin a:hover { color: #303d47; }
.signin a.inverted:hover { color: #fff; }
.signin a.inverted { color: #303d47; }
/*  --orange  */
.signin--orange { background: #ff653b; }
.signin--clear { background: none; }
body.show-fixed-header .signin-page .header { background: #ff9557; }
/* body .signin-page .navbar-nav a { color: $c-white; }*/
/*  --left  */
/* .signin--left .form { margin-left: auto; margin-right: auto; } */
/* .signin--left .signin__head { padding-right: 138px; } */
 /* --right  */
.signin--right .form { transform: translateX(5px); margin: 0 auto; }
.signin--right .signin__inner { max-width: 600px; margin-right: auto; }
.signin--right .signin__body .list-info { max-width: 425px; }
.signin--right .signin__head { padding-right: 50px; padding-left: 127px; }
.signin--right .signin__body { padding-right: 50px; padding-left: 113px; }
/*  --alt  */
.signin--alt .signin__body { padding-left: 83px; }
/* Small Desktop */
@media (max-width: 1200px) {
	.signin--right .form { -ms-transform: none; transform: none; }

	.signin .form,
	.signin .signin__inner { width: auto;}

	.signin .signin__head,
	.signin .signin__body { padding: 50px; }

	.signin .signin__body .list-radios { padding-bottom: 0; margin-top: 10px; }
	.signin .signin__body .list-checkboxes { padding-top: 0; }
}
/* Tablet Portrait */
@media (max-width: 1024px) {
	.signin .form { margin: 0 auto; }
	.signin .list-info { margin-bottom: 30px; }
	.signin .signin__body .list-radios { -ms-transform: none; transform: none; }

	.signin .signin__head { padding: 20px; }

	.signin .signin__body { padding: 20px 20px 30px; }
	.signin .signin__head h2 { font-size: 30px; }

	.signin .signin__actions { margin-top: 50px; }
	.signin .signin__actions .btn { width: 100%; }

	.signin--right .signin__body .list-info { max-width: 100%;  }
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
.slider {}
.slider .slides { list-style: none outside none; }
/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */
.socials { font-size: 20px; }
.socials ul { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; margin: 0 -8px; line-height: 1.2; list-style: none; }
.socials li { padding: 0 8px; }
.socials a { display: block; }

/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */
.subscribe p { max-width: 315px; margin-bottom: 38px; line-height: 1.25; }
.subscribe p strong { margin-bottom: 35px; font-size: 14px; line-height: 1.36; font-weight: normal; letter-spacing: 0.7px; color: #ff5c36; }
.subscribe form { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; }
/*  Subscribe Field  */
.subscribe .subscribe__field { min-width: 210px; height: 36px; padding: 0 18px; border: 2px solid #ff5c36; border-radius: 0; margin-right: 25px; margin-left: 5px; background: transparent; font-size: 14px; line-height: 32px; color: #fff; letter-spacing: 0.7px; transition: border 0.4s; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.subscribe .subscribe__field:focus { border-color: #fff; }
/*  Subscribe Button  */
.subscribe .subscribe__btn { min-width: 117px; height: 36px; padding: 0 15px; font-size: 12px; line-height: 34px; letter-spacing: 0.6px; vertical-align: top; }
.subscribe .subscribe__btn:hover { border-color: #ff5c36; }
/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
.table {}
/* ------------------------------------------------------------ *\
	Tiles
\* ------------------------------------------------------------ */
.tiles-item { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.tiles-item:nth-child(even) .tiles-item__image { -ms-flex-order: 2; order: 2; }
.tiles-item:nth-child(even) .tiles-item__content { -ms-flex-order: 1; order: 1; padding-left: 124px; }
.tiles-item .tiles-item__image {
    position: relative;
    width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}.tiles-item .tiles-item__image span { position: absolute; bottom: 24px; right: 33px; font-size: 15px; line-height: 1.2; color: #fff; font-weight: 300; font-style: italic; letter-spacing: 0.9px; }
.tiles-item .tiles-item__image--border { border: #b7b7b7 solid; border-width: 1px 0 1px 1px; }
/*  Tiles Item Content  */
.tiles-item .tiles-item__content { width: 60%; padding-right: 15px; padding-left: 35px; height: 500px; font-size: 16px; margin-bottom: 0.5rem;}
/*  Tiles Item Entry  */
.tiles-item .tiles-item__entry { max-width: 457px; padding: 60px 0 32px; }
.tiles-item .tiles-item__entry h2 { margin-bottom: 20px; letter-spacing: 1.35px; }
.tiles-item .tiles-item__entry p:not(:last-child) { margin-bottom: 20px; }
.tiles-item .tiles-item__entry--address { color: #ff5c36; }
.tiles-item--video .tiles-item__entry.tiles-item__entry--width1,
.tiles-item--video .tiles-item__entry.tiles-item__entry--width2,
.tiles-item--video .tiles-item__entry.tiles-item__entry--width3 {
	padding: 10px;
}
.tiles-item .tiles-item__entry--width1 p { max-width: 380px; }
.tiles-item .tiles-item__entry--width2 p { max-width: 395px; }
.tiles-item .tiles-item__entry--width3 p { max-width: 461px; }
/*  --video  */
.tiles-item--video .tiles-item__image { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; min-height: auto; }
.tiles-item--video .tiles-item__entry { max-width: 100%; padding: 42px 0; }
.tiles-item--video .tiles-item__entry h2 { margin-bottom: 31px; font-size: 40px; letter-spacing: 1.9px; }
.tiles-item--video .tiles-item__entry p:not(:last-child) { margin-bottom: 36px; }
/*.tiles-item--image .tiles-item__image img { padding-left: 20px; }*/
/*  --alt  */
.tiles-item--alt .tiles-item__image { min-height: auto; }
.tiles-item--alt .tiles-item__content { padding-left: 73px; }
.tiles-item--alt .tiles-item__entry { max-width: 575px; padding: 53px 0; }
.tiles-item--alt .tiles-item__entry h2 { letter-spacing: .6px; }
@media (max-width: 1200px) {
	.tiles-item .tiles-item__content { padding-left: 40px; }

	.tiles-item:nth-child(even) .tiles-item__content { padding-left: 50px; }

	.tiles-item .tiles-item__entry--width1 p,
	.tiles-item .tiles-item__entry--width3 p { max-width: 395px; }

	.tiles-item--video .tiles-item__entry h2 { margin-bottom: 15px; }
	.tiles-item--video .tiles-item__entry p:not(:last-child) { margin-bottom: 25px; }
}
@media (max-width: 1024px) {
	.tiles-item:not(:last-child) { margin-bottom: 70px; }

	.tiles-item .tiles-item__image { width: 100%; min-height: auto; padding-top: 65%; margin-bottom: 60px; }
	.tiles-item .tiles-item__content { width: 100%; padding: 0 15px; text-align: left; }
	.tiles-item .tiles-item__entry { max-width: 100%; padding: 0; margin: 0 auto; }
	.tiles-item .tiles-item__entry p { max-width: 500px; }

	.tiles-item--image.tiles-item .tiles-item__image { padding-top: 0%; }

	.tiles-item:nth-child(even) .tiles-item__image { -ms-flex-order: 1; order: 1; }
	.tiles-item:nth-child(even) .tiles-item__content { -ms-flex-order: 2; order: 2; padding: 0 15px; }

	.tiles-item .tiles-item__image--border { border: none; }
}

/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; background: transparent; transition: background 0.4s; margin-left: auto; margin-right: auto;}
/*  Video Content  */
.video .video__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s, visibility 0.4s;
}
/*  Video Button  */
.video .video__btn { position: absolute; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center; width: 120px; height: 120px; border: 10px solid white; border-radius: 100%; font-size: 34px; color: white; opacity: .7; transition: opacity 0.4s, visibility 0.4s; pointer-events: all; }
.video .video__btn i { -ms-transform: translateX(3px); transform: translateX(3px); }
.video .video__btn:hover { opacity: 1; }
.video .video__btn--dark { border-color: #313d47; color: #313d47; opacity: .5; }
.video.show-video { background: #000; }
.video.show-video .video__content { opacity: 1; visibility: visible; }
.video.show-video .video__btn { opacity: 0; visibility: hidden; pointer-events: none; }
/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
.widgets { list-style: none outside none; }

.video__btn img {
	display: block;
    padding: 10px 10px 10px 12px;
}

#get-started {
    background: #f9f9f9;
    padding: 50px 0;
}

.experience-title {
    font-size: 30px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.app-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-end;
    gap: 30px;
    margin-right: 40px;
    margin-top: 50px;
}

.app-buttons a img {
    width: 240px;
    max-width: 100%;
}

.form-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #555;
    margin-top: 50px;
}

#mc-embedded-subscribe-form .form-group {
    margin-bottom: 15px;
}

#mc-embedded-subscribe-form .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.form-label {
    font-size: 16px;
}

.text-logo-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-right: 20px;
}


.tiles-item__logo .logo-image {
  width: 200px;
  height: auto;
}
.tiles-item__logo {
      align-self: center;
}

.video-progress {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    z-index: 20;
}

.video-progress-filled {
    width: 0;
    height: 100%;
    background-color: #ff0000;
    transition: width 0.1s;
}
.image-item .step-title {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    color: #663333;
    background: #FFFFFF;
    padding: 10px 20px;
    border-radius: 10px;
    text-align: left;
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 10;
    width: 280px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

}

.image-item .step-title .step {
    text-decoration: underline;
    font-weight: bold;
}

.image-item .step-title span {
    margin-right: 5px;
}

.carousel-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

#image-scroll-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.image-item {
    flex: 0 0 100%;
    scroll-snap-align: start;
    position: relative;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

html {
    scroll-behavior: smooth;
}

.desktop-only {
    display: block;
}
.mobile-only {
    display: none;
}



@media (max-width: 768px) {
   .intro--home .intro__entry h1 { font-size: 35px; }
   .intro--home .intro__entry {
        padding-top: 200px}

   .carousel-section {
        padding: 10px;
        height: auto;
        justify-content: center;
    }

    .col-md-4 {
        display: none
    }

   .section-video {
        position: relative;
        background-size: cover;
        padding-top: 0;
        background-position: center;
    }
    .video .video__content {
        position: relative;
    }

    .section-video .video {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        padding-top: 0;
     }

   #image-scroll-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: block;
    }

   .image-item {
        flex: 0 0 auto;
        width: 100%;
        text-align: left;
    }

   .image-item img,
   .image-item picture {
        width: 100%;
        height: auto;
    }

   .image-item .step-title {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        color: #663333;
        background: #FFFFFF;
        padding: 10px 20px;
        border-radius: 10px;
        text-align: left;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        width: 180px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }

    .video {
        position: relative;
    }
   .video .video__btn {
        width: 80px;
        height: 80px;
        margin-top: 10px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        cursor: pointer;
    }

   .section {
        padding-bottom: 0px;
        padding-top: 0px;
    }

   .section-demo {
        padding: 30px 0 35px;
        background-color: #0f0e9f;
        margin-top: -9px;
    }

   .section__body {
        margin: 0 auto;
    }

    .tiles-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .tiles-item__image {
        display: none;
    }


    .text-content {
        flex-grow: 1;
    }

    .text-content p {
        font-size: 14px;
        line-height: 1.4;
    }

   .field {
        height: 40px;
        padding: 0 15px;
        line-height: 38px;
    }

   .form {
        margin-top: 30px;
    }

   .form__label {
        margin-bottom: 5px;
    }

   .form__actions {
        margin-top: 30px;
    }

   .btn {
        min-width: 0;
    }


   .intro--home .intro__entry h4 {
        font-size: 16px;
        width: 250px;
    }


    .profiles {
        display: flex;
        margin-top: 180px;
        }

   .video-progress {
        margin-top: 10px;
        width: 100%;
        height: 4px;
        background: #ddd;
        }

   .video-progress-filled {
        position: absolute;
        height: 100%;
        background: #f00;
        width: 0;
    }

   .intro__content h1 {
        font-size: 36px;
        margin-bottom: 20px;
    }

   .intro__content h4 {
        font-size: 20px;
        line-height: 1.5;
    }

   .intro__entry {
        padding: 50px 0;
    }

   .list-measurements li {
        font-size: 14px;
        line-height: 1.2;
    }

   .footer {
        padding: 30px 0;
        text-align: center;
    }
   .video {
        position: relative;
        width: 100%;
        height: auto;
        }

    .video__content {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
    }

   .form-title, .form-label {
        display: none;
    }

   .form-control {
        width: 100%;
        margin-bottom: 10px;
    }
   .app-buttons a img {
        width: 240px;
        margin-right: 30px;
    }

    .form .form__label {
        display: none;
    }

   .app-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-end;
        gap: 30px;
        margin-right: 40px;
        margin-bottom: 20px;
        margin-top: auto;
    }

   .tiles-item .tiles-item__content {
        width: 100%;
        padding: 70px 5px;
        text-align: justify;
        margin-bottom: 40px;
        }

   .intro .intro__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -50px;
        left: 0;
        background-position: center;
        background-size: cover;
    }

   .intro .intro__entry p {
        max-width: 400px;
        padding-left: 30px;
        letter-spacing: .3px;
        font-size: 36px;
        font-weight: 300;
   }

   .navbar-collapse {
        display: none;
        position: absolute;
        top: 60px;
        right: 0;
        background: white;
        width: 100%;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

   .navbar-collapse.show {
        display: block;
    }

    .navbar-collapse {
    display: none;
    }

    .navbar-collapse.show {
        display: block;
    }

   .navbar-nav {
        padding: 20px;
        list-style: none;
    }

   .navbar-nav a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }
   .navbar-collapse.collapse.show {
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: center;
   }
    .navbar-collapse {
        display: none;
    }


}

@media screen and (min-width: 769px) {
    .navbar-collapse {
        display: block;
    }
}



